<template>
  <div :is="layout">
    <v-container fluid>
      <v-data-table :headers="headers" :items="desserts"
                    :server-items-length="list_count"
                    :items-per-page="10"
                    :loading-text="$t('data_loading')"
                    :loading="table_loading"
                    @pagination="changePagination"
                    class="elevation-1"
                    :footer-props="{
          showFirstLastPage: true,
        }"

      >
<!--        <template v-slot:item.group="{item}">-->
<!--          {{ groupText(item.group) }}-->
<!--        </template>-->
        <template v-slot:item.action="{item}">
          <v-btn small color="primary" text @click="edit_open(item)"
          >{{ $t('edit') }}
          </v-btn>
          <v-btn small color="primary" text @click="del(item)">{{ $t('delete') }}</v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-btn @click="add_open" color="primary" fab class="float-btn">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px" scrollable>
      <v-card>
        <v-card-title>
          {{ $t(id ? 'system.edit_group' : 'system.add_group') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12">
              <v-text-field :label="$t('system.group_name')" v-model="info.name"></v-text-field>
            </v-col>
            <v-col :cols="12">
              <v-sheet class="pa-4 primary lighten-2">
                <v-text-field
                    v-model="search"
                    label="Search Company Directory"
                    dark
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                ></v-text-field>
                <v-checkbox
                    v-model="caseSensitive"
                    dark
                    hide-details
                    label="Case sensitive search"
                ></v-checkbox>
                <v-checkbox
                    dark
                    hide-details
                    label="checkAll"
                    @click="checkAll"
                    v-model="check"
                ></v-checkbox>
              </v-sheet>
              <v-treeview
                  selectable
                  :items="items"
                  v-model="info.permissions"
                  :search="search"
                  :filter="filter"
                  @input="change"
              ></v-treeview>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" color="primary">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {get_permission, get_group, add_group, edit_group, del_group} from "../../../api/permissions";

export default {
  name: "index",
  data() {
    return {
      layout: null,
      id: '',
      desserts: [],
      dialog: false,
      info: {},
      items: [
        {
          id: 0,
          name: 'Check All'
        }
      ],
      itemsLen: 0,
      search: null,
      caseSensitive: false,

      list_count: 0,
      table_loading: false,
      search_info: {
        pageindex: 1,
        pagesize: 10,
      },
      check: false,
    }
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.getPermission()
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("system.group"),
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("system.action"),
          align: "center",
          value: "action",
          sortable: false,
        }
      ]
    },
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
  methods: {
    async changePagination(data) {
      this.search_info.pageindex = data.page;
      this.search_info.pagesize = data.itemsPerPage;
      this.minusPage(this.list_count)
    },

    minusPage(data) {
      let totalPage = Math.ceil((data) / this.search_info.pagesize);
      let pageindex = this.search_info.pageindex > totalPage ? totalPage : this.search_info.pageindex;
      this.search_info.pageindex = pageindex < 1 ? 1 : pageindex;
      this.getGroups();
    },

    add_open() {
      this.id = ''
      this.info = {}
      this.check = false
      this.dialog = true
    },
    edit_open(item) {
      item.permissions.length === this.itemsLen ? this.check = true : this.check = false
      this.id = item.id
      this.info = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    del(data) {
      this.$confirm({
          title: this.$t('confirm_del'),
          content:this.$t('auth.delete_group'),
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel')
      }).then((dialog) => {
        del_group(data.id).then(res => {
          this.getGroups()
        })
      });
    },
    async getPermission() {
      const closeLoading = this.$loading()
      const {results} = await get_permission()
      results&&closeLoading()
      this.items = results
      // this.items[0].children=results
      this.itemsLen = results.length
    },
    async getGroups() {
      this.table_loading = true;
      const {results, count} = await get_group(this.search_info)
      this.desserts = results
      this.list_count = count
      this.table_loading = false
    },
    async submit() {
      if (this.id) {
        const data = await edit_group(this.info)
        data && (
            this.$toast({
              title: this.$t('success'),
              message: this.$t('auth.edit_success_group'),
            }), this.dialog = false, await this.getGroups()
        )
      } else {
        delete this.info.id
        const data = await add_group(this.info)
        data && (
            this.$toast({
              title: this.$t('success'),
              message: this.$t('auth.add_success_group'),
            }), this.dialog = false, await this.getGroups()
        )
      }
    },
    checkAll() {
      const res = this.items.map(item => item.id)
      if (this.info.permissions) {
        this.info.permissions.length === res.length ? this.info.permissions = [] : this.info.permissions = res
      } else
        this.info.permissions = res
    },
    change() {
      if (this.info.permissions.length < this.itemsLen) {
        this.check = false
      } else {
        this.check = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/css/global.styl';
</style>
